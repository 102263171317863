<template>
  <div class="refund">
    <refund-card title="基础信息:" >
        <el-form-item label="学生姓名：">
          {{ reDisplay.student_name }}
        </el-form-item>
        <el-form-item label="学生身份证号：">
          {{ reDisplay.student_idcard }}
        </el-form-item>
        <el-form-item label="报名学校：">
          {{ reDisplay.school_name }}
        </el-form-item>
        <el-form-item label="购买商品：">
          {{ reDisplay.goods_pack_name }}
        </el-form-item>
        <el-form-item label="订单号：">
          {{ reDisplay.order_number }}
        </el-form-item>
        <el-form-item label="订单状态：">
          {{ reDisplay.order_status }}
        </el-form-item>
        <el-form-item label="支付金额：">
          {{ reDisplay.paid_money }}
        </el-form-item>
    </refund-card>

    <refund-edit ref="form" v-model="reDisplay" is-show />
    <t-card class="card" title="操作记录" :title-style="titleStyle">
      <el-form class="content" label-width="130rem">
        <el-form-item label="发起退费人：">
          <span>{{ reDisplay.creater_name }}</span>
        </el-form-item>
        <el-form-item label="发起时间：">
          <span>{{ reDisplay.created_at }}</span>
        </el-form-item>
      </el-form>

    </t-card>
    <div class="footer-button">
      <el-button @click="$router.back()">返回</el-button>
      <el-button @click="$router.push('./refund?id='+$route.query.id+'&refund_offline_id='+$route.query.refund_offline_id)">变更</el-button>
      <el-button @click="onRecord">变更记录</el-button>
    </div>
    <t-dialog :visible="timeline" @close="timeline=false">
      <div class="timeline" v-for="item in recordData">
        <div class="left"></div>
        <div class="right">
          {{ item }}
        </div>
      </div>
      <t-result text="暂无数据" v-if="!recordData.length"></t-result>
    </t-dialog>
  </div>
</template>
<script>
import Card from "@/components/Card";
import TDialog from "@/components/Dialog.vue"
import RefundEdit from "../components/RefundEdit";
import RefundCard from "../components/RefundCard";
import {getRefundInfo} from "./Refund"
import TResult from "@/components/Result";
// import {getDetails} from "./edit";
let loading = null;
export default {
	_config:{"route":{"path":"refund-details","meta":{"title":"退费详情","style":0,"keepAlive":true}}},
  data(){
    return {
      titleStyle:{
        borderBottom:'1rem solid #eee',
        paddingBottom:'10rem',
        display:'block'
      },
      // 表单数据
      reDisplay:{
      },
      timeline:false,
      recordData:[],
      loading,
    }
  },
  watch:{
    loading(val){
      if(val)loading=this.$loading({text:"请稍后..."})
      else loading.close();
    }
  },
  mounted() {
    this.getData();
  },
  activated() {
    this.getData();
  },
  methods:{
    getData(){
      this.loading=true
      getRefundInfo(this.$route.query.refund_offline_id).then(res=>{
        this.reDisplay = res.data.data;
      }).finally(_=>this.loading=false)
    },
    submit(){
      this.$confirm("是否确认发起退费?",{
        confirmButtonText:"点错了",
        cancelButtonText:"发起退费",
        confirmButtonClass:"confirmClass",
        cancelButtonClass:"cancelClass",
      }).then(res=>{
        // console.log(res);
      }).catch(res=>{

      })
    },
    onRecord(){
      this.$_register.get("api/refund/offline-PC/refund-opt-history?id="+this.$route.query.refund_offline_id).then(res=>{
        this.recordData = res.data.data;
        this.timeline = true;
      })
    }
  },
  components:{
    TResult,
    TCard:Card,
    TDialog,
    RefundEdit,
    RefundCard
  }
}
</script>
<style lang="scss" scoped>
.refund{
  background: transparent !important;
  .card{
    background: white;
    padding:10rem;
    margin-bottom: 20rem;
    .content{
      padding: 20rem 30rem;
      .uploader{

      }
    }
  }
  .footer-button{
    background: white;
    border: none;
    line-height: 80rem;
  }
  .timeline{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20rem;
    .left{
      border-radius: 50%;
      background: #1a1d7a;
      width: 15rem;
      height:15rem;
    }
    .right{
      flex:1;
      margin-left: 20rem;
    }
  }
  .timeline:last-child{
    margin-bottom: 0;
  }
}
</style>
